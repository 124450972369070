import Rest from "../../../class/tool/Rest"
import moment from "moment"
import Period from "./Period"
moment.locale("fr")

class PeriodController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "periods"

    index() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        /*
        let list = []

        if (status === 200) {
            try {
                if (response.data === undefined) throw new Error("response.data undefined")
                if (localStorage.getItem("store") === null) throw new Error("store session null")

                let store = JSON.parse(localStorage.getItem("store"))

                const periods = response.data
                const now = new Date()
                const date = moment(store.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ")
                const dayNow = now.getDate()
                const monthNow = now.getMonth() + 1
                const yearNow = now.getFullYear()
                let dayStart = date.date()
                let monthStart = date.month() + 1
                let yearStart = date.year()

                for (let y = yearStart; y <= yearNow; y++) {
                    list.push({
                        title: y,
                        period: this.getPeriod(periods, y, null, null),
                        subs: []
                    })

                    for (let m = (y === yearStart ? monthStart : 1); m <= (y === yearNow ? monthNow : 12); m++) {
                        list[list.length - 1].subs.push({
                            title: moment(y + "-" + (m < 10 ? "0" + m : m) + "-01", "YYYY-MM-DD").format("MMMM"),
                            period: this.getPeriod(periods, y, m, null),
                            subs: []
                        })

                        for (let d = ((y === yearStart && m === monthStart) ? dayStart : 1); d <= ((y === yearNow && m === monthNow) ? dayNow : new Date(y, m, 0).getDate()); d++) {
                            list[list.length - 1].subs[list[list.length - 1].subs.length - 1].subs.push({
                                title: d,
                                period: this.getPeriod(periods, y, m, d)
                            })
                        }
                    }
                }
            }
            catch (e) {
                status = 500
            }
        }
        */
        this._callback(response.data, error, status)
    }

    getPeriod = (periods, year, month, day) => {
        const strDate = year + "-" + (month !== null ? (month < 10 ? "0" + month : month) : "0" + 1) + "-" + (day !== null ? (day < 10 ? "0" + day : day) : "0" + 1)
        let begin, end, periodType
        let periodsToCompare = null

        if (year !== null && month === null && day === null) { // find yearly period
            periodsToCompare = periods
            periodType = "Y"
        }
        else if (year !== null && month !== null && day === null) { // find monthly period
            const parentPeriod = this.getPeriod(periods, year, null, null)
            periodType = "M"

            if (parentPeriod !== null)
                periodsToCompare = parentPeriod.sub_periods
        }
        else if (year !== null && month !== null && day !== null) { // find daily period
            const parentPeriod = this.getPeriod(periods, year, month, null)
            periodType = "D"

            if (parentPeriod !== null)
                periodsToCompare = parentPeriod.sub_periods
        }

        if (periodsToCompare !== null) {
            for (let i in periodsToCompare) {
                let item = periodsToCompare[i]
                let datePeriodBegin = moment(item.timeSlotBegin, "YYYY-MM-DD HH:mm:ss")
                let datePeriodEnd = moment(item.timeSlotEnd, "YYYY-MM-DD HH:mm:ss")

                begin = datePeriodBegin.year() + "-" + ((datePeriodBegin.month() + 1) < 10 ? "0" + (datePeriodBegin.month() + 1) : (datePeriodBegin.month() + 1)) + "-" + (datePeriodBegin.date() < 10 ? "0" + datePeriodBegin.date() : datePeriodBegin.date())
                end = datePeriodEnd.year() + "-" + ((datePeriodEnd.month() + 1) < 10 ? "0" + (datePeriodEnd.month() + 1) : (datePeriodEnd.month() + 1))  + "-" + (datePeriodEnd.date() < 10 ? "0" + datePeriodEnd.date() : datePeriodEnd.date())

                if (item.type === periodType && moment(strDate, "YYYY-MM-DD").isSameOrAfter(moment(begin, "YYYY-MM-DD")) && moment(strDate, "YYYY-MM-DD").isBefore(moment(end, "YYYY-MM-DD"))) {
                    return new Period(item)
                }
            }
        }

        return null
    }
}

export default PeriodController