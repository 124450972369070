import React, { useEffect, useState } from "react";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import CancelIcon from "../../../icons/CancelIcon";
import ReApplyIcon from "../../../icons/ReApplyIcon";
import CrossIcon from "../../../icons/CrossIcon";
import {useParams} from "react-router-dom";

const InputPricelist = props => {
    const { lines, pricelists, vatrates, category, classname, shared, readonly, handleChange, handleAdd, handleRemove, handleRecovery } = props
    const [ detailsPricelist, setDetailsPricelist ] = useState([])
    const env = JSON.parse(localStorage.getItem("env"))
    const catalogs = JSON.parse(localStorage.getItem("catalogs"))
    const urlParams = useParams()

    const defineDetailsPricelist = () => {
        if (env.type === "store") return

        let prevPrice
        let prevVariationOperator
        let prevVariationValue
        let prevVariationType
        let prevVatRateId
        let index
        let first
        let quit
        let detailsPricelistTmp = detailsPricelist.slice()

        for (let i in lines) {
            prevPrice = null
            prevVariationOperator = null
            prevVariationValue = null
            prevVariationType = null
            prevVatRateId = null
            quit = false
            first = true

            for (index in lines[i].productPrice) {
                if (first) {
                    if (lines[i].productPrice[index] !== null)
                        prevPrice = lines[i].productPrice[index].value

                    first = false
                }
                else {
                    if (lines[i].productPrice[index] !== null) {
                        if (prevPrice !== lines[i].productPrice[index].value) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            quit = true
                            break
                        }
                    }
                    else {
                        if (prevPrice !== null) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            quit = true
                            break
                        }
                    }
                }
            }

            if (quit) continue

            first = true

            for (index in lines[i].productPriceVariation) {
                if (first) {
                    if (lines[i].productPriceVariation[index] !== null) {
                        prevVariationOperator = lines[i].productPriceVariation[index].variationOperator
                        prevVariationValue = lines[i].productPriceVariation[index].value
                        prevVariationType = lines[i].productPriceVariation[index].type
                    }

                    first = false
                }
                else {
                    if (lines[i].productPriceVariation[index] !== null) {
                        if (
                            prevVariationOperator !== lines[i].productPriceVariation[index].variationOperator
                            || prevVariationValue !== lines[i].productPriceVariation[index].value
                            || prevVariationType !== lines[i].productPriceVariation[index].type
                        ) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            quit = true
                            break
                        }
                    }
                    else {
                        if (prevVariationOperator !== null || prevVariationValue !== null || prevVariationType !== null) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            quit = true
                            break
                        }
                    }
                }
            }

            if (quit) continue

            first = true

            for (index in lines[i].productVat) {
                if (first) {
                    if (lines[i].productVat[index] !== null)
                        prevVatRateId = lines[i].productVat[index].vat_rate_id

                    first = false
                }
                else {
                    if (lines[i].productVat[index] !== null) {
                        if (prevVatRateId !== lines[i].productVat[index].vat_rate_id) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            break
                        }
                    }
                    else {
                        if (prevVatRateId !== null) {
                            detailsPricelistTmp.push(lines[i].pricelist.id)
                            break
                        }
                    }
                }
            }
        }

        setDetailsPricelist(detailsPricelistTmp)
    }
    const getVatRateById = id => {
        for (let i in vatrates)
            if (vatrates[i].id === id)
                return vatrates[i]

        return null
    }
    const getPricelistColor = line => {
        return line.pricelist.color !== null ? line.pricelist.color : "#FFFFFF"
    }
    const getMainPricelistColor = () => {
        for (let index in pricelists)
            if (pricelists[index].main === 1)
                return pricelists[index].color !== null ? pricelists[index].color : "#FFFFFF"

        return "#FFFFFF"
    }
    const getIdStore = () => {
        if (env.type === "company") {
            return env.stores[env.stores.findIndex(_ => _.shortName === catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores[0].shortName)].id
        }
        else
            return env.id
    }
    const getMainPrice = (idStore = 0) => {
        if (idStore === 0)
            idStore = getIdStore()

        for (let index in lines) {
            if (lines[index].pricelist.main === 1) {
                if (lines[index].productPrice[idStore] !== null)
                    return lines[index].productPrice[idStore].value
                else
                    return null
            }
        }

        return 0
    }
    const getMainVat = (idStore = 0) => {
        if (idStore === 0)
            idStore = getIdStore()

        for (let index in lines) {
            if (lines[index].pricelist.main === 1) {
                return lines[index].productVatRate[idStore].id
            }
        }

        return -1
    }
    const buildLine = (line, index) => {
        const color = getPricelistColor(line)
        let nbStores = 1
        let idMainStore = 0
        let otherStores = []

        if (env.type === "company") {
            let stores = catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores;
            idMainStore = env.stores[env.stores.findIndex(_ => _.shortName === stores[0].shortName)].id;
            nbStores = stores.length;

            for (let i in stores) {
                otherStores.push(env.stores[env.stores.findIndex(_ => _.shortName === stores[i].shortName)]);
            }

            if (otherStores.length > 0)
                delete otherStores[0];
        }
        else
            idMainStore = env.id;

        return <div key={ index } className="lineList" style={{height: (100 + (detailsPricelist.includes(line.pricelist.id) ? ((nbStores - 1) * 100) : 0)) + "px"}}>
            <div className="contLineList">
                <div className="color noBorderR noPadding" style={{backgroundColor: color}} />
                <div className="name noBorderL" style={{backgroundColor: color + "22"}}>
                    {
                        detailsPricelist.includes(line.pricelist.id)
                        && <p className="groupBy" onClick={ () => { detailStores(line.pricelist.id) } }>Grouper les boutiques</p>
                    }
                    <p className={"value" + (env.type === "store" ? " solo" : "")}>{ line.pricelist.name }</p>
                    {
                        env.type === "company"
                        && <p className={ "desc" + (detailsPricelist.includes(line.pricelist.id) ? " store" : "") } onClick={ !detailsPricelist.includes(line.pricelist.id) ? () => { detailStores(line.pricelist.id) } : () => {} }>{ detailsPricelist.includes(line.pricelist.id) ? catalogs[catalogs.findIndex(_ => _.id === parseInt(urlParams.idCatalog))].stores[0].shortName : "Toutes les boutiques" }</p>
                    }
                </div>
                <div className="vatRate">{ buildVatRate(line, color, env.type === "company" ? (detailsPricelist.includes(line.pricelist.id) ? idMainStore : 0) : 0) }</div>
                <div className="rule">{ buildPriceVariation(line, color, env.type === "company" ? (detailsPricelist.includes(line.pricelist.id) ? idMainStore : 0) : 0) }</div>
                <div className="price">{ buildUnitPrice(line, color, env.type === "company" ? (detailsPricelist.includes(line.pricelist.id) ? idMainStore : 0) : 0) }</div>
            </div>
            <div className="contStores">
                {
                    otherStores.map((store, indexStore) => (
                        <div key={ indexStore } className="contLineList">
                            <div className="color noBorderR noPadding" style={{backgroundColor: color}} />
                            <div className="name noBorderL" style={{backgroundColor: color + "22"}}>
                                <p className="value">{ line.pricelist.name }</p>
                                <p className="desc store">{ store.shortName }</p>
                            </div>
                            <div className="vatRate">{ buildVatRate(line, color, store.id) }</div>
                            <div className="rule">{ buildPriceVariation(line, color, store.id, true) }</div>
                            <div className="price">{ buildUnitPrice(line, color, store.id) }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    }
    const getIndexVatRateForValue = vatRateId => {
        let value = 0

        for (let i = 0; i < vatrates.length; i++) {
            if (vatrates[i].id === vatRateId) {
                value = i
                break
            }
        }

        return value
    }
    const buildVatRate = (line, color, idStore = 0) => {
        let idUsed = idStore

        if (idStore === 0)
            idUsed = getIdStore()

        color = line.productVat[idUsed] === null ? getMainPricelistColor() : color
        let hasRule = false
        let applyingAvaible = true
        let textRule = ""

        if (line.productVat[idUsed] !== null && line.productVat[idUsed].vat_rule_id !== null)
            applyingAvaible = false

        if (line.categoryVatRule[idUsed] !== null) {
            hasRule = true
            let maxLength = 20

            if (applyingAvaible)
                maxLength = 17

            textRule = category.name.substring(0, maxLength) + (category.name.length > maxLength ? "..." : "") + " : " + getVatRateById(line.categoryVatRule[idUsed].vat_rate_id).value
        }

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                <InputSelect
                    attribute={line.pricelist.id + "|vatRate|" + idStore}
                    returnType="pricelist"
                    classname="pricelist"
                    backgroundColor={ color }
                    classError=""
                    value={ getIndexVatRateForValue(line.productVat[idUsed] !== null ? line.productVat[idUsed].vat_rate_id : getMainVat(idUsed))  }
                    list={ vatrates }
                    newItem={ true }
                    readonly={ shared }
                    handleChange={ changeEvent } />
            </div>
            {
                hasRule
                && <div className="bottom">
                    <p className={ (!applyingAvaible || shared) ? "extend" : "" }>{ textRule }</p>
                    {
                        (applyingAvaible && !shared)
                        && <ReApplyIcon fill={ color } handleClick={ () => { recoveryEvent(line.pricelist.id, idStore, "vatRate") } } />
                    }
                </div>
            }
        </div>
    }
    const buildPriceVariation = (line, color, idStore = 0, inStores = false) => {
        let idUsed = idStore

        if (idStore === 0)
            idUsed = getIdStore()

        color = line.productPriceVariation[idUsed] === null ? getMainPricelistColor() : color
        let hasRule = false
        let applyingAvaible = true
        let textRule = ""

        if (line.pricelist.main === 1 || (line.productPriceVariation[idUsed] !== null && line.productPriceVariation[idUsed].rule_id !== null))
            applyingAvaible = false

        if (line.categoryPriceRule[idUsed] !== null) {
            hasRule = true
            let maxLength = 30

            if (applyingAvaible)
                maxLength = 27

            textRule = category.name.substring(0, maxLength)
                + (category.name.length > maxLength ? "..." : "")
                + " : "
                + line.categoryPriceRule[idUsed].variationOperator
                + (
                    line.categoryPriceRule[idUsed].type === "PERCENT"
                        ? line.categoryPriceRule[idUsed].value
                        : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(line.categoryPriceRule[idUsed].value).replace(" €", "")
                )
                + " "
                + (
                    line.categoryPriceRule[idUsed].type === "PERCENT"
                        ? "%"
                        : "EUR"
                )
        }

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                {
                    getMainPrice(idUsed) !== null
                        ? <div className="variationContainer">
                            <div className={ "variationOperator" + (line.pricelist.main === 1 ? " alone" : "") }>
                                <div className={ "bubble" + getBubblePlace(line, "variationOperator", idUsed) } style={{backgroundColor: color + "22", borderColor: color}} />
                                <div className={ "texts" + (line.pricelist.main === 1 ? " readonly" : "") }>
                                    <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationOperator|" + idStore, "pricelist", "=") } : () => {}}>=</p>
                                    <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationOperator|" + idStore, "pricelist", "-") } : () => {}}>-</p>
                                    <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationOperator|" + idStore, "pricelist", "+") } : () => {}}>+</p>
                                </div>
                            </div>
                            <div className="value">
                                <InputText
                                    attribute={ line.pricelist.id + "|variationValue|" + idStore }
                                    returnType="pricelist"
                                    classname="pricelist extend"
                                    backgroundColor={ color }
                                    classError=""
                                    value={ line.productPriceVariation[idUsed] !== null ? line.productPriceVariation[idUsed].value : getMainPrice(idUsed) }
                                    placeholder="0"
                                    readonly={ readonly }
                                    newItem={ true }
                                    toUppercase={ null }
                                    handleChange={ changeEvent } />
                            </div>
                            <div className={ "type" + (line.pricelist.main === 1 ? " alone" : "") }>
                                <div className={ "bubble" + getBubblePlace(line, "variationType", idUsed) } style={{backgroundColor: color + "22", borderColor: color}} />
                                {
                                    line.pricelist.main === 1
                                        ? <div className="texts">
                                            <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationType|" + idStore, "pricelist", "DEVISE") } : () => {}}>€</p>
                                        </div>
                                        : <div className="texts">
                                            <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationType|" + idStore, "pricelist", "DEVISE") } : () => {}}>€</p>
                                            <p className={shared ? "notAllowed" : ""} onClick={(line.pricelist.main === 0 && !shared) ? () => { changeEvent(line.pricelist.id + "|variationType|" + idStore, "pricelist", "PERCENT") } : () => {}}>%</p>
                                        </div>
                                }
                            </div>
                            <div className="remove">
                                {
                                    ((line.pricelist.main === 1 || line.productPriceVariation[idUsed] !== null) && !shared)
                                        && <div className="button">
                                            <CancelIcon click={ () => { removePrice(line.pricelist.id, idStore) } } />
                                        </div>
                                }
                            </div>
                        </div>
                        : <div className="variationContainer">
                            <div className="add">
                                {
                                    (line.pricelist.main === 1 && !shared)
                                    && <div className="button">
                                        <CrossIcon click={ () => { addPrice(line.pricelist.id, idStore) } } />
                                    </div>
                                }
                            </div>
                    </div>
                }
            </div>
            {
                hasRule
                && <div className="bottom">
                    {
                        hasRule
                        && <div className="clearing fullWidth">
                            <p className={ (!applyingAvaible || shared) ? "extend" : "" }>{ textRule }</p>
                            {
                                (applyingAvaible && !shared)
                                && <ReApplyIcon fill={ color } handleClick={ () => { recoveryEvent(line.pricelist.id, idStore, "variation") } } />
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }
    const buildUnitPrice = (line, color, idStore = 0) => {
        let idUsed = idStore

        if (idStore === 0)
            idUsed = getIdStore()

        if (getMainPrice(idUsed) === null) return

        const price = (line.pricelist.main === 1 || line.productPrice[idUsed] === null) ? getMainPrice(idUsed) : line.productPrice[idUsed].value
        color = line.productPrice[idUsed] === null ? getMainPricelistColor() : color

        return <p className="value">
            {
                new Intl.NumberFormat(
                    'de-DE',
                    { style: 'currency', currency: 'EUR' }
                ).format(price).replace(" €", "")
            }
        </p>
    }
    const getBubblePlace = (line, attribute, idStore) => {
        if (line.productPriceVariation[idStore] === null) return ""

        switch (attribute) {
            case "variationOperator":
                if (line.productPriceVariation[idStore].variationOperator === "=") return ""
                else if (line.productPriceVariation[idStore].variationOperator === "-") return " two"
                else if (line.productPriceVariation[idStore].variationOperator === "+") return " three"
                break
            case "variationType":
                if (line.productPriceVariation[idStore].type === "DEVISE") return ""
                else if (line.productPriceVariation[idStore].type === "PERCENT") return " two"
                break
            default: break
        }
    }
    const addPrice = (idPricelist, idStore) => {
        if (handleAdd === undefined || handleAdd === null || shared) return
        handleAdd(idPricelist, idStore)
    }
    const removePrice = (idPricelist, idStore) => {
        if (handleRemove === undefined || handleRemove === null || shared) return
        handleRemove(idPricelist, idStore)
    }
    const changeEvent = (attribute, returnType, value) => {
        if (handleChange === undefined || handleChange === null || readonly) return
        handleChange(attribute, returnType, value)
    }
    const recoveryEvent = (idPricelist, idStore, type) => {
        if (handleRecovery === undefined || handleRecovery === null || readonly) return
        handleRecovery(idPricelist, idStore, type, getMainPrice())
    }
    const detailStores = idPriceList => {
        let detailsTmp = detailsPricelist.slice()

        if (detailsTmp.includes(idPriceList))
            detailsTmp.splice(detailsTmp.indexOf(idPriceList), 1)
        else
            detailsTmp.push(idPriceList)

        setDetailsPricelist(detailsTmp)
    }

    useEffect(() => {
        defineDetailsPricelist()
    }, [])

    return (
        <div className={ "inputPricelist " + (classname !== undefined ? classname : "") }>
            <div className="header">
                <p className="vatTitle">TVA</p>
                <div className="contPriceTitles">
                    <p className="priceTitle">Prix</p>
                    <div className="contSubPriceTitles">
                        <p className="ruleTitle">Règle</p>
                        <p className="unitPriceTitle">P. Unitaire</p>
                    </div>
                </div>
            </div>
            <div className="contScroll">
                {
                    lines.map((line, index) => buildLine(line, index))
                }
            </div>
        </div>
    )
}

export default InputPricelist;
