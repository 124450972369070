import React from "react";
import logoMobile from '../../../image/logo/sigle-color.png';
import Logout from "./Logout";
import "../../../css/page/HeaderAccess.css";

const HeaderAccess = () => {
    return (
        <header className="header headerAccess">
            <img src={ logoMobile } alt="SkyTill Manager" className="logo" />
            <Logout />
        </header>
    );
}

export default HeaderAccess;
