import React, { useContext, useEffect, useState } from "react"
import $ from "jquery"
import moment from "moment"
import Logout from "./Logout"
import Setting from "./Setting"
//import SearchGlobal from "./SearchGlobal"
import NotificationCenter from "./NotificationCenter"
import MenuIcon from "../../icons/MenuIcon"
import LoaderCircle from "../../loader/LoaderCircle"
import ToDeployProcess from "../../overbox/deployment/ToDeployProcess"
import Informations from "./Informations"
import TypeToDeploy from "./TypeToDeploy";
import UserPreview from "../menu/UserPreview";
import EmpireIcon from "../../icons/EmpireIcon";
import AuthController from "../../../stories/_auth/Auth/AuthController"
import SessionContext from "../../../context/SessionContext"
import BackofficeContext from "../../../context/BackofficeContext";
import "../../../css/page/Header.css"
import BackgroundTasks from "../menu/BackgroundTasks";
moment.locale('fr');

const Header = props => {
    const { setUserAccountForm, setAccountsForm, setInformationForm, setSettingForm } = props
    const { handleSwitch } = useContext(SessionContext)
    const { showToDeployButton, showDeployingCatalog, showDeployingScreen } = useContext(BackofficeContext);
    const [ showListItems, setShowListItems ] = useState(false)
    const [ typeDeployProcess, setTypeDeployProcess ] = useState(null)
    const [ choiceTypeToDeploy, setChoiceTypeToDeploy ] = useState(false)
    const [ switching, setSwitching ] = useState(null);
    const env = JSON.parse(localStorage.getItem("env"))
    const company = JSON.parse(localStorage.getItem("company"))
    const store = JSON.parse(localStorage.getItem("store"))

    const handleInformationForm = () => {
        setInformationForm(true)
    }
    const handleUserAccountForm = () => {
        setUserAccountForm(true)
    }
    const handleAccountsForm = () => {
        setAccountsForm(true)
    }
    const handleSettingForm = () => {
        setSettingForm(true)
    }
    const handleClickMenuMobile = () => {
        $(".menu").addClass("visible")
    }
    const switchTarget = (target, id) => {
        if (switching !== null)
            return;

        setSwitching({target, id});
        handleSwitch(target, id);
    }
    const displayCompany = () => {
        const companyName = getCompanyName()

        if (companyName !== null && companyName !== "") {
            let okToSwitchCompany = (env.type === "store" && store !== null && AuthController.hasRules(["admin", "accountant", "analyst", "products", "manager", "tech"], "company", store.id))

            return <>
                {
                    (switching === null || (switching !== null && (switching.target !== "company" || switching.id !== store.company_id)))
                        ? <EmpireIcon className={"iconEmpire"} onClick={okToSwitchCompany ? () => { switchTarget("company", store.company_id) } : () => {}} />
                        : <LoaderCircle display="loaderSwitchEmpire" strokeWidth="12" stroke="#464646" />
                }
                <div id="storeCompany" className={"company" + (okToSwitchCompany ? " pointer" : "")} onClick={okToSwitchCompany ? () => { switchTarget("company", store.company_id) } : () => {}}>
                    <p className="item">Groupe</p>
                    {
                        (switching === null || (switching !== null && (switching.target !== "company" || switching.id !== store.company_id)))
                            ? <p className="name">{companyName}</p>
                            : <LoaderCircle display="loader" strokeWidth="12" stroke="#464646" />
                    }
                </div>
            </>;
        }
    }
    const getCompanyName = () => {
        if (localStorage.getItem("originEnv") === null) return ""
        let environment = JSON.parse(localStorage.getItem("originEnv"))

        switch (environment.type) {
            case "empire":
                for (let i in environment.companies) {
                    if (Object.keys(company).length > 0) {
                        if (environment.companies[i].id === company.id)
                            return environment.companies[i].name;
                    }
                    else if (Object.keys(store).length > 0) {
                        for (let j in environment.companies[i].stores) {
                            if (environment.companies[i].stores[j].id === store.id) {
                                return environment.companies[i].name;
                            }
                        }
                    }
                }

                break;
            case "company":
                return environment.name;
            default: break;
        }

        return ""
    }
    const displayArrowMany = () => {
        if (hasManyChilds())
            return <div id="manyArrow" className="many" onClick={ handleClickOnArrow } />
    }
    const hasManyChilds = () => {
        let environment = JSON.parse(localStorage.getItem("originEnv"))

        switch (env.type) {
            case "company":
                if (environment.type === "empire") {
                    let listItems = [];

                    for (let i in environment.companies) {
                        if ((environment.companies[i].shortName !== undefined && environment.companies[i].shortName !== null) || (environment.companies[i].name !== undefined && environment.companies[i].name !== null))
                            listItems.push(environment.companies[i]);
                    }

                    return listItems.length > 1
                }

                break
            case "store":
                if (environment.type === "empire") {
                    for (let i in environment.companies) {
                        for (let j in environment.companies[i].stores) {
                            if (environment.companies[i].stores[j].id === env.id) {
                                return environment.companies[i].stores.length > 1
                            }
                        }
                    }
                }
                else if (environment.type === "company")
                    return environment.stores.length > 1

                break
            default: break
        }

        return false
    }
    const handleClickOnArrow = () => {
        if (!hasManyChilds()) return

        let listStores = "#listStores";
        let storeCompany = "#storeCompany";
        let storeName = "#storeName";
        let menu = "#menu";
        let manyArrow = "#manyArrow";

        if (window.matchMedia('(max-width: 640px)').matches) {
            $(listStores).css("left", "0px")
            $(listStores).css("min-width", $(document).width() + "px")
        }
        else {
            switch (env.type) {
                case "company":
                    $(listStores).css("min-width", ($(storeCompany).outerWidth() + $(manyArrow).outerWidth() + 15) + "px")
                    $(listStores).css("left", $(menu).width() + $(storeCompany).position().left + "px")

                    break
                case "store":
                    $(listStores).css("min-width", ($(storeName).outerWidth() + $(manyArrow).outerWidth() + 15) + "px")
                    $(listStores).css("left", $(menu).width() + $(storeName).position().left + "px")

                    break
                default: break
            }
        }
        
        setShowListItems(!showListItems)
    }
    const buildListEnv = () => {
        let environment = JSON.parse(localStorage.getItem("originEnv"))

        switch (env.type) {
            case "company":
                if (environment.type === "empire")
                    return buildListItems("company", environment.companies)

                break
            case "store":
                if (environment.type === "empire") {
                    for (let i in environment.companies) {
                        for (let j in environment.companies[i].stores) {
                            if (environment.companies[i].stores[j].id === env.id) {
                                return buildListItems("store", environment.companies[i].stores)
                            }
                        }
                    }
                }
                else if (environment.type === "company")
                    return buildListItems("store", environment.stores)

                break
            default: break
        }
    }
    const buildListItems = (type, list) => {
        list = list.sort((s1, s2) => {
            if (s1.name > s2.name) return 1
            else if (s1.name < s2.name) return -1
            else return 0
        })
        let listItems = [];

        for (let i in list) {
            if ((list[i].shortName !== undefined && list[i].shortName !== null) || (list[i].name !== undefined && list[i].name !== null))
                listItems.push(list[i]);
        }

        return listItems.map((item, index) => (
            <p key={ index } className={"linkStore" + (env.type === type && env.id === item.id ? " selected" : "")} onClick={ (env.type === type && env.id === item.id ? () => {} : () => { switchTarget(type, item.id) }) }>
                {
                    (switching === null || (switching !== null && (switching.target !== type || switching.id !== item.id)))
                        ? item.shortName !== undefined ? item.shortName : item.name
                        : <LoaderCircle display="loader" strokeWidth="12" stroke="#464646" />
                }
            </p>
        ))
    }
    const openToDeployProcess = (type = null) => {
        if (type !== null || showToDeployButton < 3) {
            if (type !== null)
                setTypeDeployProcess(type === 1 ? "catalog" : "screen")
            else
                setTypeDeployProcess(showToDeployButton === 1 ? "catalog" : "screen")
        }
        else {
            setChoiceTypeToDeploy(true)
        }
    }
    const closeChoiceTypeToDeploy = () => {
        setChoiceTypeToDeploy(false)
    }
    const validChoiceTypeToDeploy = type => {
        closeChoiceTypeToDeploy()
        openToDeployProcess(type)
    }
    const closeToDeployProcess = () => {
        setTypeDeployProcess(null)
    }
    const buildCatalogDeployingDatas = () => {
        if (showDeployingCatalog.length === 0)
            return;

        return <>
            {
                showDeployingCatalog.map((deployment, index) => buildButtonCatalogDeploying(deployment, index))
            }
        </>
    }
    const buildButtonCatalogDeploying = (deployment, index) => {
        let label = ""
        let object = Object.assign({}, deployment);
        deployment = object.planned_deployments_from.length > 0 ? object.planned_deployments_from[0] : object.planned_deployments_to[0]
        if (deployment === undefined) return

        let proposeAt = deployment.proposeAt !== undefined ? deployment.proposeAt : deployment.propose_at
        let inProgress = new Date(proposeAt) <= new Date()

        if (inProgress)
            label = "Déploiement " + object.name + " en cours..."
        else
            label = "Déploiement " + object.name + " planifié le " + moment(proposeAt).format("DD/MM/YYYY à HH:mm")

        return <div key={index} className={ "hButton catalogDeploying " + (object.planned_deployments_from.length > 0 ? "upload" : "download") }>
            <p>{ label }</p>
            {
                inProgress
                && <LoaderCircle display="loader hButton" hide="" strokeWidth="10" stroke="#FFFFFF" />
            }
        </div>
    }
    const buildScreenDeployingDatas = () => {
        if (showDeployingScreen.length === 0)
            return;

        return <>
            {
                showDeployingScreen.map((deployment, index) => buildButtonScreenDeploying(deployment, index))
            }
        </>
    }
    const buildButtonScreenDeploying = (deployment, index) => {
        let label = ""
        let object = Object.assign({}, deployment);
        deployment = object.planned_deployments_from.length > 0 ? object.planned_deployments_from[0] : object.planned_deployments_to[0]
        if (deployment === undefined) return

        let proposeAt = deployment.proposeAt !== undefined ? deployment.proposeAt : deployment.propose_at
        let inProgress = new Date(proposeAt) <= new Date()

        if (inProgress)
            label = "Déploiement " + object.name + " en cours..."
        else
            label = "Déploiement " + object.name + " planifié le " + moment(proposeAt).format("DD/MM/YYYY à HH:mm")

        return <div key={index} className={ "hButton catalogDeploying " + (object.planned_deployments_from.length > 0 ? "upload" : "download") }>
            <p>{ label }</p>
            {
                inProgress
                && <LoaderCircle display="loader hButton" hide="" strokeWidth="10" stroke="#FFFFFF" />
            }
        </div>
    }

    useEffect(() => {
        if (showListItems)
            $("#manyArrow").addClass("opened")
        else
            $("#manyArrow").removeClass("opened")
    }, [showListItems]);

    return (
        <header className="header Header">
            <MenuIcon classname="iconMenuMobile" click={ handleClickMenuMobile } />
            <div className="wrapAccess">
                {
                    displayCompany()
                }
                {
                    (env.type === "store" && store !== null)
                    && <div id="storeName" className="store">
                        <p className="item">Boutique</p>
                        <p className="name">{ store.name }</p>
                    </div>
                }
                <nav id="listStores" className={ "listStores" + (showListItems ? " visible" : "") }>
                    {
                        buildListEnv()
                    }
                </nav>
                {
                    displayArrowMany()
                }
            </div>
            <div className="containerIcons">
                {
                    /*
                    <SearchGlobal />
                     */
                }
                {
                    /*
                    (env.type === "store" && AuthController.hasRules(["admin", "skytech"], env.type, env.id))
                    && <Accounts handleClick={ handleAccountsForm } />
                     */
                }
                {
                    (env.type === "store" && AuthController.hasRules(["admin", "skytech"], env.type, env.id))
                    && <Informations handleClick={ handleInformationForm } />
                }
                {
                    (env.type === "store" && AuthController.hasRules(["admin", "tech", "skytech"], env.type, env.id))
                    && <Setting handleClick={ handleSettingForm } />
                }
                <NotificationCenter />
                <Logout />
            </div>
            <UserPreview setAccountForm={handleUserAccountForm} />
            {
                /*
                <BackgroundTasks />
                 */
            }
            <div className="containerButtons">
                {
                    showToDeployButton !== null
                    && <div className="hButton catalogToDeploy" onClick={() => { openToDeployProcess() }}>
                        <p>Déployer les modifications</p>
                    </div>
                }
                {
                    showDeployingCatalog !== null
                    && buildCatalogDeployingDatas()
                }
                {
                    showDeployingScreen !== null
                    && buildScreenDeployingDatas()
                }
            </div>
            {
                choiceTypeToDeploy
                && <TypeToDeploy cancel={ closeChoiceTypeToDeploy } save={ validChoiceTypeToDeploy } />
            }
            {
                typeDeployProcess !== null
                && <ToDeployProcess type={typeDeployProcess} handleClose={ closeToDeployProcess } />
            }
        </header>
    )
}

export default Header;
