import React from "react";

const AccessIcon = props => {
    const { classname, fill } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 297.3 297.3" className={classname} fill={fill}>
        <path d="M218.8 148.4c-7.7 0-14.2-4.4-17.5-10.8-3.2 6.4-9.8 10.8-17.5 10.8s-14.3-4.4-17.5-10.8c-3.2 6.4-9.9 10.8-17.5 10.8-7.7 0-14.3-4.4-17.5-10.8-3.2 6.4-9.9 10.8-17.5 10.8s-14.3-4.4-17.5-10.8c-3.2 6.4-10 10.8-17.6 10.8-3.4 0-6.4-.9-9.5-2.4v72.8H58.8v19.4h179.6v-19.4h-9.7v-73.2c-3 1.8-6.2 2.8-9.9 2.8m-69.9 10.5h59.9v29.6h-59.9zm-59.9 0h39.9v49.6H89zM96.7 128.7l12.7-39.9h26.8l-5.5 39.9h36.1l-5.3-39.9h27.6l11.8 39.9h37.4L219 88.8V59.2H79.3v29.6l-20 39.9z" />
      </svg>
    )
}

export default AccessIcon;
