import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import HeaderAccess from "../../header/HeaderAccess";
import CompanyItem from "./item/CompanyItem";
import ObjectSheet from "../../../sheet/ObjectSheet";
import AccountList from "../../../overbox/account/AccountList";
import SessionContext from "../../../../context/SessionContext";
import "../../../../css/page/content/access/Access.css";

const Access = () => {
    const { handleCheckBigBisoux } = useContext(SessionContext);

    const buildEnv = () => {
        const env = JSON.parse(localStorage.getItem("originEnv"));

        switch (env.type) {
            case "empire":
                let companies = [];
                let stores = {
                    noGroup: true,
                    id: 0,
                    name: "Autre boutiques",
                    rule: "",
                    stores: []
                }

                for (let i in env.companies) {
                    if (env.companies[i].name !== null)
                        companies.push(env.companies[i]);
                    else {
                        if (env.companies[i].stores.length > 0)
                            stores.stores.push(env.companies[i].stores[0]);
                    }
                }

                if (stores.stores.length > 0)
                    companies.push(stores);

                console.log("companies", companies);

                return companies.map((company, index) => <CompanyItem key={index} company={company} />);
            case "company":
                return <CompanyItem company={env} />;
            default:
                break;
        }
    }

    useEffect(() => {
        document.title = "Back office - Mes boutiques";
        handleCheckBigBisoux();
    }, []);

    return (
        <div className="mainAccess">
            <HeaderAccess />
            <div className={"subHeaderAccessFiller"} />
            {
                buildEnv()
            }
            <Switch>
                <Route path="/access/stores/:id/informations">
                    <ObjectSheet objectType="store" />
                </Route>
                <Route path="/access/stores/:id/accounts">
                    <AccountList />
                </Route>
            </Switch>
        </div>
    )
}

export default Access;
