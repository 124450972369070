import React from "react"

const AccessStoreIcon = props => {
    const { classname, fill } = props

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fill} className={classname}>
            <path d="M5 21h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zm5-14 6 5-6 5z"/>
        </svg>
    )
}

export default AccessStoreIcon
