import React, { useContext } from "react";
import StoreMarketIcon from "../../../../icons/StoreMarketIcon";
import SessionContext from "../../../../../context/SessionContext";
import "../../../../../css/page/content/access/StoreItem.css";

const StoreItem = props => {
    const { store } = props;
    const { handleSwitch } = useContext(SessionContext);

    const handleSelectStore = () => {
        handleSwitch('store', store.id);
    }

    return (
        <div className="storeItem" onClick={handleSelectStore}>
            <StoreMarketIcon classname={"picture"} />
            <p className="title">{ store.name }</p>
            <p className="address">
                { store.address }<br />
                { store.postalCode + " " + store.city }
            </p>
        </div>
    )
}

export default StoreItem;