import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import saveAs from "file-saver";
import {Link} from "react-router-dom";
import LoaderCircle from "../../../loader/LoaderCircle";
import DownloadIcon from "../../../icons/DownloadIcon";
import AnalyzeIcon from "../../../icons/AnalyzeIcon";
import LeftArrow from "../../../icons/LeftArrow";
import RightArrow from "../../../icons/RightArrow";
import StringTools from "../../../../class/tool/StringTools";
import StoreSetting from "../../../../stories/_setting/StoreSettings/StoreSetting";
import ArchiveController from "../../../../stories/_legaldata/Archives/ArchiveController";
import PeriodController from "../../../../stories/_legaldata/Periods/PeriodController";
import Store from "../../../../stories/_account/Stores/Store";
import BackofficeContext from "../../../../context/BackofficeContext";
import "../../../../css/page/content/closing/Closing.css";
import "../../../../css/form/Form.css";

const Closing = () => {
    moment.locale('fr');
    const item = "closings";
    const titleWindow = "Clôtures de caisses";
    const store = new Store(JSON.parse(localStorage.getItem("store")));
    const storeSettings = new StoreSetting(JSON.parse(localStorage.getItem("storeSettings")));
    const { setErrorText } = useContext(BackofficeContext);
    const [ loading, setLoading ] = useState(false);
    const [ list, setList ] = useState([]);
    const [ toUnzip, setToUnzip ] = useState(null);
    const [ fileToSave, setFileToSave ] = useState(null);
    const [ keyToUnzip, setKeyToUnzip ] = useState(null);
    const [ remove, setRemove ] = useState(false);
    const [ stateLeftArrow, setStateLeftArrow ] = useState("visible");
    const [ stateRightArrow, setStateRightArrow ] = useState("visible");
    const [ yearSelected, setYearSelected ] = useState(moment().year());
    const [ monthSelected, setMonthSelected ] = useState(moment().month());

    const getClosings = () => {
        setLoading(true);

        let controller = new PeriodController();
        controller._callback = returnGetClosings;
        controller.index();
    }
    const returnGetClosings = (listObjects, error, status) => {
        setLoading(false)

        switch (status) {
            case 200:
                setList(listObjects);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la récupération des clôtures");
                break;
        }
    }
    const buildCalendar = () => {
        return <div className="calendarClosings">
            { lineYear() }
            { lineMonth() }
            { weeks() }
        </div>;
    }
    const lineYear = () => {
        const store = new Store(JSON.parse(localStorage.getItem("store")));
        let createdAt = moment(store.created_at);
        let years = [];

        for (let i = createdAt.year(); i <= moment().year(); i++)
            years.push(i);

        return <div className={"yearPart"}>
            <select value={yearSelected} onChange={changeYear}>
                {
                    years.map((year, index) => (
                        <option key={index} value={year}>Année { year }</option>
                    ))
                }
            </select>
            { buildContentYear() }
        </div>;
    }
    const changeYear = event => {
        setYearSelected(parseInt(event.currentTarget.value));
    }
    const lineMonth = () => {
        let momentMonth = moment();
        momentMonth.year(yearSelected);
        momentMonth.month(monthSelected);

        return <div className={"monthPart"}>
            <div className={"left " + stateLeftArrow}><LeftArrow click={prevMonth} /></div>
            <div className={"month"}>{ StringTools.capitalizeFirstLetter(momentMonth.format("MMMM")) }</div>
            <div className={"right " + stateRightArrow}><RightArrow click={nextMonth} /></div>
            { buildContentMonth() }
        </div>;
    }
    const getSelectedMoment = () => {
        let selectedMoment = moment();
        selectedMoment.year(yearSelected);
        selectedMoment.month(monthSelected);

        return selectedMoment;
    }
    const prevMonth = () => {
        let newMoment = getSelectedMoment();

        newMoment.add(-1, "months");

        if (controlLimitMonth(newMoment, false)) {
            setYearSelected(newMoment.year());
            setMonthSelected(newMoment.month());
        }
    }
    const nextMonth = () => {
        let newMoment = getSelectedMoment();

        newMoment.add(1, "months");

        if (controlLimitMonth(newMoment, true)) {
            setYearSelected(newMoment.year());
            setMonthSelected(newMoment.month());
        }
    }
    const controlLimitMonth = (newMoment, up) => {
        if (up)
            return newMoment.year() <= moment().year();
        else {
            const store = new Store(JSON.parse(localStorage.getItem("store")));
            return newMoment.year() >= moment(store.created_at).year();
        }
    }
    const controlArrowBounds = () => {
        const store = new Store(JSON.parse(localStorage.getItem("store")));
        let selectedMoment = getSelectedMoment();
        let momentToAdd = selectedMoment.clone();
        let momentToSubstract = selectedMoment.clone();

        if (momentToAdd.add(1, "months").year() > moment().year())
            setStateRightArrow("disable");
        else
            setStateRightArrow("visible");

        if (momentToSubstract.add(-1, "months").year() < moment(store.created_at).year())
            setStateLeftArrow("disable");
        else
            setStateLeftArrow("visible");
    }
    const weeks = () => {
        let weeks = [];
        let week;
        let currentStart = getSelectedMoment();
        currentStart.date(1);
        currentStart.hour(1);
        let dayStart = currentStart.isoWeekday();
        currentStart.add((dayStart-1)*-1, "days");

        for (let i = 0; i < 6; i++) {
            week = [];

            for (let j = 0; j < 7; j++) {
                week.push({
                    date: currentStart.clone().hour(storeSettings.timeSlotBegin).minute(0).second(0),
                    dateString: currentStart.format("DD/MM/YYYY")
                });

                currentStart.add(1, "days");
            }

            weeks.push(week);
        }

        return <div className={"weeksPart"}>
            <table className={"weeksTable"}>
                <thead>
                    <tr className={"header"}>
                        <th>Lundi</th>
                        <th>Mardi</th>
                        <th>Mercredi</th>
                        <th>Jeudi</th>
                        <th>Vendredi</th>
                        <th>Samedi</th>
                        <th>Dimanche</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        weeks.map((week, index) => buildWeek(week, index))
                    }
                </tbody>
            </table>
        </div>;
    }
    const buildWeek = (week, index) => {
        return <tr key={index} className={"week"}>
            {
                week.map((day, index) => buildDay(day, index))
            }
        </tr>;
    }
    const buildDay = (day, index) => {
        return <td key={index} className={"day" + (isActualDay(day) ? " actual" : "") + (!isCurrentDayOfSelectedMonth(day) ? " otherMonth" : "")}>
            <p className={"number"}>{ day.date.format("DD") }</p>
            { buildContentDay(day) }
        </td>;
    }
    const isActualDay = day => {
        return (day.date >= moment().hour(0).minute(0).second(0) && day.date <= moment().hour(23).minute(59).second(59));
    }
    const isCurrentDayOfSelectedMonth = day => {
        let selectedDate = getSelectedMoment();
        let startMonth = selectedDate.clone().date(1).hour(0).minute(0).second(0);
        let endMonth = startMonth.clone().add(1, "months").add(-1, "seconds");

        return (day.date >= startMonth && day.date <= endMonth);
    }
    const searchInPeriods = (type, date) => {
        if (list === undefined || list === null || list.length === 0)
            return null;

        let annualyPeriod = list.find(_ => _.type === "Y" && date.isSameOrAfter(moment(_.timeSlotBegin)) && date.isSameOrBefore(moment(_.timeSlotEnd)));
        if (annualyPeriod === undefined) return null;

        if (type === "Y")
            return annualyPeriod;

        let monthlyPeriod = annualyPeriod.sub_periods.find(_ => _.type === "M" && date.isSameOrAfter(moment(_.timeSlotBegin)) && date.isSameOrBefore(moment(_.timeSlotEnd)));
        if (monthlyPeriod === undefined) return null;

        if (type === "M")
            return monthlyPeriod;

        let dailyPeriod = monthlyPeriod.sub_periods.find(_ => _.type === "D" && date.isSameOrAfter(moment(_.timeSlotBegin)) && date.isSameOrBefore(moment(_.timeSlotEnd)));
        if (dailyPeriod === undefined) return null;

        return dailyPeriod;
    }

    const buildContentYear = () => {
        let dateSelected = getSelectedMoment();
        let dateToSearch = dateSelected.month(1).date(1).hour(storeSettings.timeSlotBegin).minute(0).second(0);
        let period = searchInPeriods("Y", dateToSearch);

        return <div className={"datas"}>
            {
                period === null
                    ? <p className={"empty"}></p>
                    : <>
                        {
                            period.closingDatas === null
                                ? <p className={"empty inProgress"}>En cours</p>
                                : <>
                                    <p className={"amountIclTax"}>{ StringTools.number_format(period.closingDatas.amountInclTax, 2, ",", " ") } €</p>
                                    <Link to={"/analyze/year/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-") + "/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-")}>
                                        <div className={"analyze"}>
                                            <AnalyzeIcon />
                                            <p>Analyser</p>
                                        </div>
                                    </Link>
                                    {
                                        (period.archive !== undefined && period.archive !== null)
                                        && <div className={"download"} onClick={ () => { callDownloadFile(period) } }>
                                            <DownloadIcon />
                                            <p>Télécharger</p>
                                        </div>
                                    }
                                </>
                        }
                    </>
            }
        </div>;
    }
    const buildContentMonth = () => {
        let dateSelected = getSelectedMoment();
        let dateToSearch = dateSelected.date(1).hour(storeSettings.timeSlotBegin).minute(0).second(0);
        let createdAt = moment(store.created_at).date(1).hour(0).minute(0).second(0);
        let period = searchInPeriods("M", dateToSearch);

        return <div className={"datas"}>
            {
                period === null
                    ? <p className={"empty"}>{ (dateToSearch.isSameOrAfter(moment(createdAt)) && dateToSearch.isSameOrBefore(moment()) ? "Fermé" : "") }</p>
                    : <>
                        {
                            period.closingDatas === null
                                ? <p className={"empty inProgress"}>En cours</p>
                                : <>
                                    <p className={"amountIclTax"}>{ StringTools.number_format(period.closingDatas.amountInclTax, 2, ",", " ") } €</p>
                                    <Link to={"/analyze/month/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-") + "/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-")}>
                                        <div className={"analyze"}>
                                            <AnalyzeIcon />
                                            <p>Analyser</p>
                                        </div>
                                    </Link>
                                    {
                                        (period.archive !== undefined && period.archive !== null)
                                        && <div className={"download"} onClick={ () => { callDownloadFile(period) } }>
                                            <DownloadIcon />
                                            <p>Télécharger</p>
                                        </div>
                                    }
                                </>
                        }
                    </>

            }
        </div>;
    }
    const buildContentDay = day => {
        let period = searchInPeriods("D", day.date);
        let createdAt = moment(store.created_at).hour(0).minute(0).second(0);

        return <div className={"datas"}>
            {
                period === null
                    ? <p className={"empty"}>{ (day.date.isSameOrAfter(createdAt) && day.date.isSameOrBefore(moment()) ? "Fermé" : "") }</p>
                    : <>
                        {
                            period.closingDatas === null
                                ? <p className={"empty inProgress"}>En cours</p>
                                : <>
                                    <p className={"nbSales"}>{ period.closingDatas.nbSales } vente{ (period.closingDatas.nbSales > 1 ? "s" : "") }</p>
                                    <p className={"amountIclTax"}>{ StringTools.number_format(period.closingDatas.amountInclTax, 2, ",", " ") } €</p>
                                    <div className={"containerButtons"}>
                                        <Link to={"/analyze/day/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-") + "/" + moment(period.timeSlotBegin).format("DD/MM/YYYY").replaceAll("/", "-")}>
                                            <div className={"analyze"}>
                                                <AnalyzeIcon />
                                            </div>
                                        </Link>
                                        {
                                            (period.archive !== undefined && period.archive !== null)
                                            && <div className={"download"} onClick={ () => { callDownloadFile(period) } }>
                                                <DownloadIcon />
                                            </div>
                                        }
                                    </div>
                                </>
                        }
                    </>
            }
        </div>;
    }

    const callDownloadFile = period => {
        if (period === null || period.closingDatas === null || period.archive === undefined || period.archive === null)
            return

        let controller = new ArchiveController()
        controller._callback = handleCallDownloadFile
        controller.zip(period.archive.id)

        setToUnzip(period)
    }
    const handleCallDownloadFile = (response, error, status) => {
        switch (status) {
            case 200:
                setKeyToUnzip(response.data.key)
                downloadFile(response.data)
                break
            default:
                alert("Une erreur s'est produite lors du téléchargement du fichier")
                break
        }
    }
    const downloadFile = datas => {
        let controller = new ArchiveController()
        controller._callback = handleDownloadFile
        controller.download(datas.uri, datas.key)
    }
    const handleDownloadFile = (response, error, status) => {
        switch (status) {
            case 200:
                setFileToSave(response.data)
                break
            default:
                alert("Une erreur s'est produite lors du téléchargement du fichier")
                break
        }
    }
    const saveFile = () => {
        const url = new Blob([fileToSave])
        const nameFile = "cloture_" + toUnzip.closingDatas.number + "_" + moment(toUnzip.timeSlotBegin).format("DD-MM-YYYY") + ".zip"
        saveAs(url, nameFile)
        setRemove(true)
    }
    const removeFile = () => {
        if (toUnzip === null || toUnzip.closingDatas === null || toUnzip.archive === undefined || toUnzip.archive === null)
            return

        let controller = new ArchiveController()
        controller._callback = handleRemoveFile
        controller.unzip(toUnzip.archive.id, keyToUnzip)

        setFileToSave(null)
        setKeyToUnzip(null)
        setToUnzip(null)
        setRemove(false)
    }
    const handleRemoveFile = (response, error, status) => {}

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        getClosings();
    }, []);
    useEffect(() => {
        if (toUnzip === null || fileToSave === null) return

        saveFile()
    }, [ toUnzip, fileToSave ]);
    useEffect(() => {
        if (!remove || toUnzip === null || keyToUnzip === null) return

        removeFile()
    }, [ toUnzip, remove ]);
    useEffect(() => {
        controlArrowBounds();
    }, [monthSelected, yearSelected]);

    return(
        <div className={ "main " + item }>
            {
                buildCalendar()
            }
        </div>
    )
}

export default Closing;
