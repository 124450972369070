import React, {useContext} from "react";
import StoreItem from "./StoreItem";
import SessionContext from "../../../../../context/SessionContext";
import "../../../../../css/page/content/access/CompanyItem.css";

const CompanyItem = props => {
    const { company } = props;
    const { handleSwitch } = useContext(SessionContext);

    const handleSelectCompany = () => {
        handleSwitch('company', company.id);
    }

    const listStores = stores => {
        stores = stores.sort((s1, s2) => {
            if (s1.name > s2.name) return 1
            else if (s1.name < s2.name) return -1
            else return 0
        })

        return stores.map(item => (
            <StoreItem key={ item.id } store={ item } />
        ))
    }

    return(
        <div className="wrapCompany">
            <div className="company">
                <p className="title">{ company.name !== null ? company.name : "Boutiques" }</p>
                {
                    company.noGroup === undefined
                    && <p className={"access"} onClick={handleSelectCompany}>
                        {
                            window.matchMedia('(max-width: 640px)').matches
                                ? "Accéder"
                                : "Accéder à l'interface multi-boutique"
                        }
                    </p>
                }
            </div>
            <div className="stores">
                {
                    company.stores !== undefined && company.stores.length > 0
                        ? listStores(company.stores)
                        : <p className="empty">Aucune boutique</p>
                }
            </div>
        </div>
    )
}

export default CompanyItem
